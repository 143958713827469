//React
import React, { useState, useEffect } from 'react'
//MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';


import { getFunctions, httpsCallable } from "firebase/functions";

import { useDashboard } from '../contexts/DashboardContext';

export default function Home() {

  const { setSelectedCampaign, setNavigationComponent, brandId, getBrandId, campaignList, fetchCampaigns } = useDashboard();
  


  useEffect(async () => {
    if (brandId === null) {
      getBrandId();
    } else {
      fetchCampaigns();
    }
  }, [brandId]);

  


  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Campaign Name</TableCell>
              <TableCell align="center">Short Description</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {campaignList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  setSelectedCampaign(row);
                  setNavigationComponent("tracker");
                }
                }
              >
                <TableCell align="center">
                  {row.status === "ACTIVE" &&
                    <Chip label={row.status} color="success" size="small"/>
                  }
                  {row.status === "ENDED" &&
                    <Chip label={row.status} size="small"/>
                  }
                   {row.status === "PAUSED" &&
                    <Chip label={row.status} color="warning" size="small"/>
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.shortDesc}</TableCell>
                <TableCell align="center">{row.category}</TableCell>
                <TableCell align="center">{row.startDate}</TableCell>
                <TableCell align="center">{row.endDate}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}