//React
import React from 'react'
import { useHistory } from 'react-router-dom';
//Context
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';
//MUI
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//Icons
import * as AiIcons from "react-icons/ai"
import * as FiIcons from "react-icons/fi"

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

export default function TopBar(){

    const {logout} = useAuth()
    const history = useHistory()

    const { themeMode, setThemeMode, toggleDrawer, open, brandName, clearUser} = useDashboard();

    
  

    async function handleLogout(){
        try{
            clearUser();
            await logout()
     
            history.pushState('/login')
        }catch{}
    }

    return(
        <>
        <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <AiIcons.AiOutlineMenu />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Zellit  
              </Typography>

              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {brandName}  
              </Typography>

              <IconButton color="inherit" onClick={() => { setThemeMode(!themeMode) }}>
                <AiIcons.AiFillBulb/>
              </IconButton>

              <IconButton color="inherit" onClick={handleLogout}>
                <FiIcons.FiLogOut />
              </IconButton>
             
            </Toolbar>
          </AppBar>
        </>
    )
}