import React, { useState, useEffect } from 'react'

//Material UI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';


import { getFunctions, httpsCallable } from "firebase/functions";

import { useDashboard } from '../contexts/DashboardContext';

//Custom style for modal popup
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function CampaignTracker() {

    //Styling for component grid items
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    //Imports from the dashboard context
    const { selectedCampaign, brandId, getBrandId } = useDashboard();

    useEffect(() => {
        if (brandId === null) {
            getBrandId();
        }
    }, []);

    //Open/close handlers for the form dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //Open/close handlers for the modal
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    //State for the edit form components
    const [name, setName] = useState(selectedCampaign.name)
    const [descShort, setDescShort] = useState(selectedCampaign.shortDesc)
    const [descLong, setDescLong] = useState(selectedCampaign.longDesc)
    const [category, setCategory] = useState(selectedCampaign.category)
    const [startDateTime, setStartDateTime] = useState(selectedCampaign.startDate)
    const [endDateTime, setEndDateTime] = useState(selectedCampaign.endDate)
    const [redemptionMult, setRedemptionMult] = useState(selectedCampaign.redemptionMult)
    const [maxPts, setMaxPts] = useState(selectedCampaign.maxPts)
    const [status, setStatus] = useState(selectedCampaign.status)

    //State for the results status
    const [results, setResults] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        const formJSON = {
            "campaignName": name,
            "shortDesc": descShort,
            "longDesc": descLong,
            "startDate": startDateTime,
            "endDate": endDateTime,
            "brandId": brandId,
            "category": category,
            "maxPts": maxPts,
            "redemptionMult": redemptionMult,
            "campaignId": selectedCampaign.id,
            "status": status
        }

        setOpen(false);
        setOpenModal(true);
        setResults('Updating Campaign...')

        const functions = getFunctions();
        const updateCampaign = httpsCallable(functions, 'updateCampaign');
        try {
            await updateCampaign({ campaignJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    selectedCampaign.name = name;
                    selectedCampaign.shortDesc = descShort;
                    selectedCampaign.longDesc = descLong;
                    selectedCampaign.category = category;
                    selectedCampaign.startDate = startDateTime;
                    selectedCampaign.endDate = endDateTime;
                    selectedCampaign.redemptionMult = redemptionMult;
                    selectedCampaign.maxPts = maxPts;
                    selectedCampaign.status = status;
                })
        } catch {
            setResults("Error updating the campaign.");
        }
    }

    return (
        <div className='CampaignTracker'>
            <Container>
                <Typography variant="h2" align="center">Campaign Tracker</Typography>
                <Button variant="outlined" onClick={handleClickOpen}>Edit</Button>
            </Container>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h3" >{selectedCampaign.name}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h6" >Short Description</Typography>
                            <Typography variant="body2" >{selectedCampaign.shortDesc}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h6" >Status</Typography>
                            {selectedCampaign.status === "ACTIVE" &&
                                <Chip label={selectedCampaign.status} color="success" size="small" />
                            }
                            {selectedCampaign.status === "ENDED" &&
                                <Chip label={selectedCampaign.status} size="small" />
                            }
                            {selectedCampaign.status === "PAUSED" &&
                                <Chip label={selectedCampaign.status} color="warning" size="small" />
                            }
                        </Item>
                        <Item>
                            <Typography variant="h6" >Category</Typography>
                            <Typography variant="body2" >{selectedCampaign.category}</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >Start</Typography>
                            <Typography variant="body2" >{selectedCampaign.startDate}</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >End</Typography>
                            <Typography variant="body2" >{selectedCampaign.endDate}</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" ># Reach</Typography>
                            <Typography variant="body2" >Reach</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" ># Zealots</Typography>
                            <Typography variant="body2" >Zealots</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >$ EMV</Typography>
                            <Typography variant="body2" >EMV</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >$ Cost</Typography>
                            <Typography variant="body2" >Cost</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >$ Return</Typography>
                            <Typography variant="body2" ></Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >ROI</Typography>
                            <Typography variant="body2" >ROI</Typography>
                        </Item>
                        <Item>
                            <Typography variant="h6" >% of Max</Typography>
                            <Typography variant="body2" >% of Max</Typography>
                        </Item>

                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h6" >Long Description</Typography>
                            <Typography variant="body2" >{selectedCampaign.longDesc}</Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>

            <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {results}
                    </Typography>
                </Box>
            </Modal>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update Campaign</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '90%' }, }}
                            noValidate
                            autoComplete="off">
                            <TextField id="name" label="Campaign Name" value={name} required variant="standard" onChange={e => setName(e.target.value)} />
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="category-label">Status</InputLabel>
                                <Select id="category" value={status} required variant="standard" onChange={e => setStatus(e.target.value)}>
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"ENDED"}>ENDED</MenuItem>
                                    <MenuItem value={"PAUSED"}>PAUSED</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id="descShort" label="Short Description" value={descShort} required variant="standard" onChange={e => setDescShort(e.target.value)} />
                            <TextField id="descLong" label="Long Description" value={descLong} required variant="standard" multiline='true' minRows='5' onChange={e => setDescLong(e.target.value)} />
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select id="category" value={category} required variant="standard" onChange={e => setCategory(e.target.value)}>
                                    <MenuItem value={"Product-Focus"}>Product-Focus</MenuItem>
                                    <MenuItem value={"Customer-Service"}>Customer-Service</MenuItem>
                                    <MenuItem value={"Competitor-Contrast"}>Competitor-Contrast</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                            <DateTimePicker label="Start" renderInput={(props) => <TextField {...props} />} value={startDateTime} onChange={(newValue) => { setStartDateTime(newValue); }} />
                            <DateTimePicker label="End" renderInput={(props) => <TextField {...props} />} value={endDateTime} onChange={(newValue) => { setEndDateTime(newValue); }} />
                            <TextField id="redemptionMult" label="Redemption Multiplier" value={redemptionMult} type="number" InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setRedemptionMult(e.target.value)} />
                            <TextField id="maxPTs" label="Max PT's allowed" value={maxPts} type="number" InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setMaxPts(e.target.value)} />

                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default CampaignTracker