import React, {useState} from 'react'
import { useAuth } from '../contexts/AuthContext'
import {Link} from 'react-router-dom'

//Material UI
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function ForgotPassword(){

    const [email, setEmail] = useState('')
    const {resetPassword} = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e){
        e.preventDefault()
        try{
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(email)
            setMessage('Password reset email sent.')
        } catch {
            setError('Failed to reset password.')
        }
        setLoading(false)
    }
    return(
        <>
            <Card>
                <CardContent>
                    <Typography variant="h2" align="center">Forgot Password</Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <Alert severity="success">{message}</Alert>}
                    <Box component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '90%' },
                            }}
                            noValidate
                            autoComplete="off">

                        <TextField id="email" label="Email" value={email} required variant="standard" onChange={e => setEmail(e.target.value)}/>
                        <Button variant="contained" disabled={loading} onClick={handleSubmit} className="w-100 " type="submit">Reset Password</Button>
                    </Box>
                    <Typography align="center">
                        <Link to="/login">Login</Link>
                    </Typography>
                </CardContent>
            </Card>
            <Typography align="center">
                Need an account? <Link to="/signup">Sign Up</Link>
            </Typography>
        </>
    )
}