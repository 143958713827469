//React
import React, {useState} from 'react'
//MUI
import Typography from '@mui/material/Typography';


export default function Reports(){
    return(
        <>
        <Typography variant="h2" align="center">Reports</Typography>
        </>
    )
}