import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
function Graph1(props){

function buildTimeArrays() {

  //let sortedZellits = props.zellits.slice().sort((a, b) => b.followDate = a.followDate);




  /*const groups = props.zellits.slice().reduce((groups, zellit) => {
   const date = zellit.followDate.split('T')[0];
  if (!groups[date]) {
    groups[date] = [];
  }
  groups[date].push(zellit);
  return groups;
}, {});
console.log(groups);*/
  const groups = props.zellits.slice().reduce((groups, zellit) => {
    const date = zellit.followDate.split('T')[0];
    if (!groups[date]) {
      groups[date] = 0;
    }
    groups[date] = groups[date] + 1;
    return groups;
  }, {});

  console.log(groups);
  /*const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      count: groups[date].length,
      msDate: new Date(date).getTime()
    };
  });
  console.log(groupArrays);*/

  //let SortedGroupArrays= groupArrays.slice().sort((a, b) => b.msDate = a.msDate);
  //groups.sort();
  //console.log(SortedGroupArrays);
 // console.log(groupArrays);

  const keys = Object.keys(groups).sort();
  const startDate = new Date(keys[0]);
  const endDate = new Date(keys[keys.length-1]);

  console.log(keys);
  console.log(startDate);
  console.log(endDate);

  let data = [];
  for(let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)){
    //console.log(currentDate);
    let indexDate = currentDate.toISOString().split('T')[0];
    if(groups[indexDate] !== undefined){
      let tmp = {name:indexDate, count:groups[indexDate]};
      data.push(tmp);
    }
    else{
      let tmp = {name:indexDate, count:0};
      data.push(tmp);
    }
  }
  console.log(data);
  return data;
  //

    /*const sortedZellits = props.zellits.slice().sort((a, b) => b.followDate = a.followDate);
    let dayCount = [];
    //let start = sortedZellits[0].followDate;
    let start = new Date(sortedZellits[0].followDate);
    let index = new String(start.toDateString());

    dayCount[index] = {name: index, count: 0};
    sortedZellits.forEach(function (obj) {
      let tmpDate = new Date(obj.followDate);
      
        if (start.getFullYear() == tmpDate.getFullYear() && start.getMonth() == tmpDate.getMonth() && start.getDate() == tmpDate.getDate()) {
            let tmp = dayCount[index].count;
            dayCount[index].count = tmp + 1;
        }
        else {
            start = new Date(obj.followDate);
            index = new String(start.toDateString());
            dayCount[index] = {name: index, count: 0};
            let tmp = dayCount[index].count;
            dayCount[index].count = tmp + 1;
        }
    });
    let data = [];
    for (const [key, value] of Object.entries(dayCount)) {

       let tmp = {name:value.name, count:value.count};
       data.push(tmp);
      }
   console.log(data);
    return data;*/
}


    return (

      <>
         <Typography variant="h5" align="center">New Zellits/Date</Typography>

      <ResponsiveContainer width="70%" height="70%">
        <LineChart
          width={500}
          height={300}
          data={buildTimeArrays()}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="count" stroke="#8884d8"/>
        </LineChart>
      </ResponsiveContainer>

      </>
    );

}
export default Graph1;
