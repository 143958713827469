import './App.css';
import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components
import Signup from "./Pages/Signup";
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import { AuthProvider } from './contexts/AuthContext'
import { DashboardProvider } from './contexts/DashboardContext';
import PrivateRoute from "./components/PrivateRoute";
import BrandMain from "./Pages/Main"
// MUI
import CssBaseline from '@mui/material/CssBaseline';


 

function App() {
 
  
  return (
    <>
        <CssBaseline />
        <Router>
        <DashboardProvider>
          <AuthProvider>
            
            <Switch>
              <PrivateRoute exact path="/" component={BrandMain} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
            </AuthProvider>
            </DashboardProvider>
          
        </Router>
    </>
  );
} 

export default App;
