import React, {useState} from 'react'

//Material UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//Firebase
import { getFunctions, httpsCallable } from "firebase/functions";
import { useDashboard } from '../contexts/DashboardContext';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  


function CreateBrand () {
    const [name, setName] = useState('')
    const [shortDesc, setShortDesc] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [url, setUrl] = useState('')
    

    const [results, setResults] = useState('')

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNavigationComponent("home");
    }

    const {setNavigationComponent} = useDashboard();
    
    async function handleSubmit(e){
        e.preventDefault()
        const formJSON = {
            "name": name,
            "shortDesc": shortDesc,
            "logoUrl": logoUrl,
            "url":url,
        }
        setOpen(true);
        setResults('Creating Brand...')
        const functions = getFunctions();
        const createBrand = httpsCallable(functions, 'createBrand');
        try{
            await createBrand({brandJSON:JSON.stringify(formJSON)})
                .then((result) => {
                    setResults(result.data);
                    clearState();
             })
        } catch {
            setResults("Error creating Brand.");
        }
    }

    function clearState(){
        setName('');
        setShortDesc('');
        setUrl('');
        setLogoUrl('');
    }

    return (
        <div className ='CreateBrand'>
                <Card>
                    <CardContent>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {results}
                            </Typography>
                        </Box>
                    </Modal>
                        <Typography variant="h2" align="center">Brand Setup</Typography>
                        <Box component="form"
                                sx={{'& .MuiTextField-root': { m: 1, width: '90%' },}}
                                noValidate
                                autoComplete="off">
                            <TextField id="name" label="Brand Name" value={name} required variant="standard" onChange={e => setName(e.target.value)}/>
                            <TextField id="descShort" label="Brand Description" value={shortDesc} required variant="standard" onChange={e => setShortDesc(e.target.value)}/>
                            <TextField id="url" label="Site URL" value={url} required variant="standard"  onChange={e => setUrl(e.target.value)}/>
                            <TextField id="logoUrl" label="Logo URL" value={logoUrl} required variant="standard" onChange={e => setLogoUrl(e.target.value)}/>
                            <Button variant="contained" onClick={handleSubmit} className="w-100 " type="submit">Launch Brand</Button>
                        </Box>
                    </CardContent>
                </Card>
          
        </div>
    );
}
export default CreateBrand