import React, { useState, useEffect } from 'react'

//Date Adapter
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

//Material UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Modal from '@mui/material/Modal';

//Firebase
import { getFunctions, httpsCallable } from "firebase/functions";

import { useDashboard } from '../contexts/DashboardContext';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function CampaignBuilder() {
    const [name, setName] = useState('')
    const [descShort, setDescShort] = useState('')
    const [descLong, setDescLong] = useState('')
    const [category, setCategory] = useState('')
    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')
    const [redemptionMult, setRedemptionMult] = useState('')
    const [maxPTs, setMaxPTs] = useState('')
    const [status, setStatus] = useState('')

    const [results, setResults] = useState('')

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNavigationComponent("home");
    }

    const { setNavigationComponent, brandId, getBrandId } = useDashboard();

    useEffect(() => {
        if (brandId === null) {
            getBrandId();
        }
    }, []);

    async function handleSubmit(e) {
        e.preventDefault()
        const formJSON = {
            "campaignName": name,
            "shortDesc": descShort,
            "longDesc": descLong,
            "startDate": startDateTime,
            "endDate": endDateTime,
            "brandId": brandId,
            "category": category,
            "maxPts": maxPTs,
            "redemptionMult": redemptionMult,
            "status": status
        }
        setOpen(true);
        setResults('Creating Campaign...')
        const functions = getFunctions();
        const createCampaign = httpsCallable(functions, 'createCampaign');
        try {
            await createCampaign({ campaignJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    clearState();
                })
        } catch {
            setResults("Error creating Campaign.");
        }
    }

    function clearState() {
        setName('');
        setDescShort('');
        setDescLong('');
        setCategory('');
        setStartDateTime('');
        setEndDateTime('');
        setRedemptionMult('');
        setMaxPTs('');
        setStatus('');
    }

    return (
        <div className='CampaignBuilder'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Card>
                    <CardContent>
                        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {results}
                                </Typography>
                            </Box>
                        </Modal>
                        <Typography variant="h2" align="center">Campaign Setup</Typography>
                        <Box component="form"
                            sx={{ '& .MuiTextField-root': { m: 1, width: '90%' }, }}
                            noValidate
                            autoComplete="off">
                            <TextField id="name" label="Campaign Name" value={name} required variant="standard" onChange={e => setName(e.target.value)} />
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="category-label">Status</InputLabel>
                                <Select id="category" value={status} required variant="standard" onChange={e => setStatus(e.target.value)}>
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"ENDED"}>ENDED</MenuItem>
                                    <MenuItem value={"PAUSED"}>PAUSED</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id="descShort" label="Short Description" value={descShort} required variant="standard" onChange={e => setDescShort(e.target.value)} />
                            <TextField id="descLong" label="Long Description" value={descLong} required variant="standard" multiline='true' minRows='5' onChange={e => setDescLong(e.target.value)} />
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select id="category" value={category} required variant="standard" onChange={e => setCategory(e.target.value)}>
                                    <MenuItem value={"Product-Focus"}>Product-Focus</MenuItem>
                                    <MenuItem value={"Customer-Service"}>Customer-Service</MenuItem>
                                    <MenuItem value={"Competitor-Contrast"}>Competitor-Contrast</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                            <DateTimePicker label="Start" renderInput={(props) => <TextField {...props} />} value={startDateTime} onChange={(newValue) => { setStartDateTime(newValue); }} />
                            <DateTimePicker label="End" renderInput={(props) => <TextField {...props} />} value={endDateTime} onChange={(newValue) => { setEndDateTime(newValue); }} />
                            <TextField id="redemptionMult" label="Redemption Multiplier" value={redemptionMult} type="number" InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setRedemptionMult(e.target.value)} />
                            <TextField id="maxPTs" label="Max PT's allowed" value={maxPTs} type="number" InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setMaxPTs(e.target.value)} />

                            <Button variant="contained" onClick={handleSubmit} className="w-100 " type="submit">Submit to Launch</Button>
                        </Box>
                    </CardContent>
                </Card>
            </LocalizationProvider>
        </div>
    );
}
