//React
import React, {useState} from 'react'
import { useDashboard } from '../contexts/DashboardContext';
//MUI
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//Icons
import * as BsIcons from "react-icons/bs"
import * as BiIcons from "react-icons/bi"
import * as AiIcons from "react-icons/ai"
import * as FiIcons from "react-icons/fi"

export default function DashboardListItems(){
    const {setNavigationComponent} = useDashboard();

    return(
        <>
        <ListItem button key="Home" onClick={() => { setNavigationComponent("home") }}>
            <ListItemIcon>
                <AiIcons.AiOutlineHome/>
            </ListItemIcon>
            <ListItemText primary="Home" />
        </ListItem>
        
        <ListItem button key="Campaign Builder" onClick={() => { setNavigationComponent("builder") }}>
            <ListItemIcon>
                <BsIcons.BsPencil/>
            </ListItemIcon>
            <ListItemText primary="Campaign Builder" />
        </ListItem>
        <ListItem button key="Zellits" onClick={() => { setNavigationComponent("zellits") }}>
            <ListItemIcon>
                <BsIcons.BsPeople/>
            </ListItemIcon>
            <ListItemText primary="Zellits" />
        </ListItem>
        <ListItem button key="Rewards Hub" onClick={() => { setNavigationComponent("rewards-hub") }}>
            <ListItemIcon>
                <AiIcons.AiOutlineStar/>
            </ListItemIcon>
            <ListItemText primary="Rewards Hub" />
        </ListItem>
        <ListItem button key="Reports" onClick={() => { setNavigationComponent("reports") }}>
            <ListItemIcon>
                <BsIcons.BsFileBarGraph/>
            </ListItemIcon>
            <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button key="Messaging Center" onClick={() => { setNavigationComponent("messaging-center") }}>
            <ListItemIcon>
                <BiIcons.BiMessageDots/>
            </ListItemIcon>
            <ListItemText primary="Messaging Center" />
        </ListItem>
        <ListItem button key="Settings" onClick={() => { setNavigationComponent("settings") }}>
            <ListItemIcon>
                <FiIcons.FiSettings/>
            </ListItemIcon>
            <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button key="Test Data" onClick={() => { setNavigationComponent("test-data") }}>
            <ListItemIcon>
                <FiIcons.FiLock/>
            </ListItemIcon>
            <ListItemText primary="Test Data" />
        </ListItem>
        </>
    )
}