//React
import React, { useState, useEffect } from 'react'
//MUI
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl';
import { useDashboard } from '../contexts/DashboardContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import ButtonGroup from '@mui/material/ButtonGroup';

import ZellitCountChart from "./ZellitCountChart";

function Zellits() {

    useEffect(async () => {
        fetchZellits();
    }, []);




    const { brandId } = useDashboard();

    const [zellits, setZellits] = useState([])
    async function fetchZellits() {
        const formJSON = {
            "brandId": brandId
        }

        const functions = getFunctions();
        const getZellits = httpsCallable(functions, 'getZellits');
        try {
            await getZellits({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    const zellits = [];
                    const tmp = JSON.parse(result.data);
                    let i = 1;
                    tmp.forEach(function (obj) {
                        let z = { id: i, handle: obj.handle, campaignCount: obj.campaignCount, instaPost: obj.instaPost, fbPost: obj.fbPost, tikTokPost: obj.tikTokPost, reach: obj.reach, points: obj.points, emv: obj.emv, followDate: obj.followDate, followDateFormatted: new Date(obj.followDate).toDateString() }
                        zellits.push(z);
                        i = i + 1;
                    });
                    setZellits(zellits);
                    return true;
                })
        } catch {
            console.log("Error getting Zellits.");
        }
    }

    /*useEffect(async () => {
        if (zellits.length > 0) {
            buildTimeArrays();
        }
    }, [zellits]);*/



    const columns = [
        { field: 'handle', headerName: 'Handle', width: 150 },
        { field: 'campaignCount', type: 'number', headerName: 'Campaigns', width: 150 },
        { field: 'instaPost', type: 'number', headerName: 'Instagram Posts', width: 150 },
        { field: 'fbPost', type: 'number', headerName: 'Facebook Posts', width: 150 },
        { field: 'tikTokPost', type: 'number', headerName: 'TikTok Posts', width: 150 },
        { field: 'reach', type: 'number', headerName: 'Reach', width: 150 },
        { field: 'points', type: 'number', headerName: 'Points', width: 150 },
        {
            field: 'emv', type: 'number', headerName: '$EMV', width: 150, valueFormatter: (params) => {
                const valueFormatted = Number(params.value).toLocaleString();
                return `$ ${valueFormatted}`;
            }
        },
        {
            field: 'followDate', type: 'date', headerName: 'Follow Date', width: 150, valueFormatter: (params) => {
                let tmp = new Date(params.value);
                const valueFormatted = tmp.toDateString();
                return `${valueFormatted}`;
            }
        }
    ];

    //Styling for component grid items
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [zellit, setZellit] = useState(null);

    //Open/close handlers for the form dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [selectedComp, setSelectedComp] = useState('data-grid');


    return (
        <div className='Zellits'>
            <Card>
                <CardContent>
                    <div style={{ height: 1000, width: '100%' }}>
                        <ButtonGroup variant="text" aria-label="outlined primary button group">
                            <Button onClick={() => setSelectedComp('data-grid')}>Zellit List</Button>
                            <Button onClick={() => setSelectedComp('charts')}>Charts</Button>
                        </ButtonGroup>
                        {selectedComp === 'data-grid' &&
                            <DataGrid
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                rows={zellits} columns={columns}
                                onSelectionModelChange={(id) => {
                                    const selectedZellit = id;
                                    const selectedRow = zellits.filter((row) =>
                                        selectedZellit == row.id
                                    );
                                    setZellit(JSON.parse(JSON.stringify(selectedRow)));
                                    handleClickOpen();
                                }}
                            />
                        }

                        {selectedComp === 'charts' &&
                            <ZellitCountChart zellits={zellits}/>
                        }
                    </div>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogContent>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography variant="h3" >{zellit !== null && zellit[0].handle}</Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <FormControl fullWidth variant="standard">
                                                <Button variant="contained">Message</Button>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography variant="h6" >Status</Typography>
                                            {zellit !== null && zellit[0].campaignCount == 0 &&
                                                <Chip label={`${zellit[0].campaignCount} ACTIVE CAMPAIGNS`} color="default" size="small" />
                                            }
                                            {zellit !== null && zellit[0].campaignCount == 1 &&
                                                <Chip label={`${zellit[0].campaignCount} ACTIVE CAMPAIGN`} color="success" size="small" />
                                            }
                                            {zellit !== null && zellit[0].campaignCount > 1 &&
                                                <Chip label={`${zellit[0].campaignCount} ACTIVE CAMPAIGNS`} color="success" size="small" />
                                            }

                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >Campaign Count</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].campaignCount}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >Instagram Post Count</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].instaPost}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >Facebook Post Count</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].fbPost}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >TikTok Post Count</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].tikTokPost}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >Reach</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].reach}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >Points</Typography>
                                            <Typography variant="body2" >{zellit !== null && zellit[0].points}</Typography>
                                        </Item>
                                        <Item>
                                            <Typography variant="h6" >$ EMV</Typography>
                                            <Typography variant="body2" >${zellit !== null && zellit[0].emv}</Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <FormControl fullWidth variant="standard">
                                                <Button variant="contained">Some other action</Button>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>


                </CardContent>
            </Card>
        </div>
    );
}
export default Zellits