//React
import React from 'react';
//MUI
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
//Components
import TopBar from '../components/TopBar';
import SideBar from '../components/SideBar';
import MainContent from '../components/MainContent'
//Context
import { useDashboard } from '../contexts/DashboardContext';

  export default function BrandMainContent() {
    //Get components from the DashboardContext
    const {navigationComponent, selectedTheme} = useDashboard();

    return (
        <ThemeProvider theme={selectedTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <TopBar/>
          <SideBar/>
          <MainContent/>

        </Box>
        </ThemeProvider>
    );
  }
  