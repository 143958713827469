import React, { useContext, useState } from 'react'
//MUI
import { createTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";

const DashboardContext = React.createContext()

export function useDashboard() {
  return useContext(DashboardContext)
}

export function DashboardProvider({ children }) {
  //Navigation
  const [navigationComponent, setNavigationComponent] = useState('home');

  function clearUser(){
    setBrandId(null);
    setBrandName(null);
    setSelectedCampaign(null);
    setCampaignList([]);
    setNavigationComponent('home');
  }

  //Brand Id associated with this user
  const [brandId, setBrandId] = useState(null);
  const [brandName, setBrandName] = useState(null);
  async function getBrandId() {
    setBrandId(null);
    setBrandName(null);
    setSelectedCampaign(null);
    setCampaignList([]);
    
    const functions = getFunctions();
    const getBrandId = httpsCallable(functions, 'getBrandId');
    try {
      await getBrandId()
        .then((result) => {
          const brandInfo = JSON.parse(result.data);
          setBrandId(brandInfo.brandId);
          setBrandName(brandInfo.brandName);
          return brandId;
        })
    } catch {
      console.log("Error getting your brand information.");
    }
  }

  //Theme
  const [themeMode, setThemeMode] = useState();
  const selectedTheme = createTheme({
    palette: {
      mode: themeMode ? "dark" : "light",
    },
  });

  //Drawer Toggle
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [selectedCampaign, setSelectedCampaign] = useState();

  //Campaigns for the brand
  const [campaignList, setCampaignList] = useState([])
  async function fetchCampaigns() {
    const formJSON = {
      "brandId": brandId
    }

    const functions = getFunctions();
    const getCampaigns = httpsCallable(functions, 'getCampaigns');
    try {
      await getCampaigns({ requestJSON: JSON.stringify(formJSON) })
        .then((result) => {
          const campaigns = [];
          const tmp = JSON.parse(result.data);
          tmp.forEach(function (obj) {
            campaigns.push(obj);
          });
          setCampaignList(campaigns);
          return true;
        })
    } catch {
      console.log("Error getting Campaigns.");
    }
  }

  const value = {
    navigationComponent,
    setNavigationComponent,
    themeMode,
    setThemeMode,
    selectedTheme,
    open,
    setOpen,
    toggleDrawer,
    selectedCampaign,
    setSelectedCampaign,
    brandId,
    getBrandId,
    setBrandId,
    campaignList,
    setCampaignList,
    fetchCampaigns,
    brandName,
    clearUser
  }

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}