import React, {useContext, useState, useEffect} from 'react'
import {auth} from '../firebase'

import { useDashboard } from '../contexts/DashboardContext';
const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({children}){

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    const {clearUser, getBrandId} = useDashboard();

    //Change these methods if the auth destination changes ie. firebase moves to AWS cognito
    function signup(email, password){
       return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email, password){
        clearUser();
        getBrandId();
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout(){
        clearUser();
         return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user =>{
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [])


    const value = { 
        currentUser,
        login,
        signup,
        logout,
        resetPassword
     }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}