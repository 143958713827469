import React, {useState} from 'react'
import { useAuth } from '../contexts/AuthContext'
import {Link, useHistory} from 'react-router-dom'

//Material UI
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Signup(){

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const {signup} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e){
        e.preventDefault()

        if(password !== passwordConfirm){
            return setError('Passwords Do Not Match')
        }

        try{
            setError('')
            setLoading(true)
            await signup(email, password)
            history.push("/")
        } catch {
            setError('Failed to create an account')
        }
        setLoading(false)
    }
    return(
        <>
            <Card>
                <CardContent>
                    <Typography variant="h2" align="center">Sign Up</Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Box component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '90%' },
                            }}
                            noValidate
                            autoComplete="off">
                        <TextField id="email" label="Email" value={email} required variant="standard" onChange={e => setEmail(e.target.value)}/>
                        <TextField id="password" label="Password" type="password" value={password} required variant="standard" onChange={e => setPassword(e.target.value)} />
                        <TextField id="confirmPassword" label="Confirm Password" type="password" value={passwordConfirm} required variant="standard" onChange={e => setPasswordConfirm(e.target.value)} />
                        <Button variant="contained" onClick={handleSubmit} disabled={loading} className="w-100 " type="submit">Sign Up</Button>
                    </Box>
                </CardContent>
            </Card>
            <Typography align="center">
                Already have an account? <Link to="/login">Log In</Link>
            </Typography>
        </>
    )
}