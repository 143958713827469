//React
import React from 'react';
//Components
import Zellits from '../components/Zellits';
import TestData from '../components/TestData';
import CampaignTracker from '../components/CampaignTracker';
import CampaignBuilder from '../components/CampaignBuilder';
import Home from '../components/Home';
import RewardsHub from '../components/RewardsHub';
import Reports from '../components/Reports';
import MessagingCenter from '../components/MessagingCenter';
import Settings from '../components/Settings';
import CreateBrand from './CreateBrand';
//MUI
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//Context
import { useDashboard } from '../contexts/DashboardContext';

export default function MainContent(){
    const {navigationComponent} = useDashboard();
    return(
        <>
        <Box component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
           
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: '100%' } }}>
                {navigationComponent === "home" &&
                     <Home/>
                }
                {navigationComponent === "tracker" &&
                    <CampaignTracker/>
                }
                {navigationComponent === "builder" &&
                    <CampaignBuilder/>
                }
                {navigationComponent === "zellits" &&
                    <Zellits/>
                }
                {navigationComponent === "rewards-hub" &&
                    <RewardsHub/>
                }
                {navigationComponent === "reports" &&
                    <Reports/>
                }
                {navigationComponent === "messaging-center" &&
                    <MessagingCenter/>
                }
                {navigationComponent === "settings" &&
                    <CreateBrand/>
                }  
                {navigationComponent === "test-data" &&
                    <TestData/>
                }  
              </Box>
            </Container>
          </Box>
        </>
    )
}