//React
import React, { useState } from 'react'
//MUI
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { useDashboard } from '../contexts/DashboardContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import CardMedia from '@mui/material/CardMedia';


function TestData() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [spawnZellitCount, setSpawnZellitCount] = useState('')
    const [spawnZellitDate, setSpawnZellitDate] = useState('')
    const { brandId } = useDashboard();
    const [zellitId, setZellitId] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": brandId,
            "count": spawnZellitCount,
            "dateString": spawnZellitDate
        }
        setOpen(true);
        setResults('Spawing Zellits...')
        const functions = getFunctions();
        const spawnZellits = httpsCallable(functions, 'spawnZellits');
        try {
            await spawnZellits({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }


    async function testFollowed(e) {
        e.preventDefault()
       console.log("Starting");
        const functions = getFunctions();
        const getFollowed = httpsCallable(functions, 'getFollowedBrands');
        try {
            await getFollowed()
                .then((result) => {
                   console.log(JSON.stringify(JSON.parse(result.data)));
                })
        } catch {
            setResults("ERROR");
        }
    }
    
    async function frequencyOffer(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": '-MsgQg0xA8IA9rbKQh9Z',
            "followerUID": zellitId,
        }
        setOpen(true);
        setResults('Generating a Frequency Offer')
        const functions = getFunctions();
        const CreateFrequencyOffer = httpsCallable(functions, 'CreateFrequencyOffer');
        try {
            await CreateFrequencyOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }

    async function aovOffer(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": '-MsgQg0xA8IA9rbKQh9Z',
            "followerUID": zellitId,
        }
        setOpen(true);
        setResults('Generating a AOV Offer')
        const functions = getFunctions();
        const CreateAOVOffer = httpsCallable(functions, 'CreateAOVOffer');
        try {
            await CreateAOVOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }

    async function referralOffer(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": brandId,
            "followerUID": 'KWTNLrr9OMPU23z0Jei53xz1KQI2',
        }
        setOpen(true);
        setResults('Generating a Referral Offer')
        const functions = getFunctions();
        const CreateReferralOffer = httpsCallable(functions, 'CreateReferralOffer');
        try {
            await CreateReferralOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }

    async function generalOffer(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": brandId,
            "followerUID": 'KWTNLrr9OMPU23z0Jei53xz1KQI2',
        }
        setOpen(true);
        setResults('Generating a General Offer')
        const functions = getFunctions();
        const CreateGeneralOffer = httpsCallable(functions, 'CreateGeneralOffer');
        try {
            await CreateGeneralOffer({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }


    const [results, setResults] = useState('')

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    }

    const [openPermission, setOpenPermission] = React.useState(false);
    const handleClosePermission = () => {
        setOpenPermission(false);
    }

    async function calculateBrandSummaryData(e) {
        e.preventDefault()
        const formJSON = {
            "brandId": '-MsgQg0xA8IA9rbKQh9Z',
        }
        setOpen(true);
        setResults('Calculating Brand Summary Data')
        const functions = getFunctions();
        const CalculateSummaryDataForBrand = httpsCallable(functions, 'CalculateSummaryDataForBrand');
        try {
            await CalculateSummaryDataForBrand({ requestJSON: JSON.stringify(formJSON) })
                .then((result) => {
                    setResults(result.data);
                    if(result.data === "PERMISSION-ERROR"){
                        handleClose();
                        setOpenPermission(true);
                    }
                })
        } catch {
            setResults("Error spawning Zellits.");
        }
    }


    return (
        <div className='test-data'>
            <Card>
                <CardContent>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {results}
                            </Typography>
                        </Box>
                    </Modal>

                    <Modal open={openPermission} onClose={handleClosePermission} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                        <Typography id="modal-modal-title" align="center" variant="h6" component="h2">
                                NO TEST DATA FOR YOU
                            </Typography>
                            <CardMedia
                                component="img"
                                height="194"
                                image="noSoup.jpg"
                                alt=""
                            />
                            
                            <Typography id="modal-modal-title" align="center" variant="overline" component="h2">
                                You do not have the necessary permissions to spawn test data.
                            </Typography>
                        </Box>
                    </Modal>

                    <Typography variant="h2" align="center">Spawn Zellits</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { m: 1, width: '90%' }, }}
                        noValidate
                        autoComplete="off">
                        <TextField id="Zellit Count" label="Zellit Count" value={spawnZellitCount} type="number" InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setSpawnZellitCount(e.target.value)} />
                        <TextField id="Zellit Follow Date" label="Zellit Follow Date" value={spawnZellitDate} InputLabelProps={{ shrink: true, }} required variant="standard" onChange={e => setSpawnZellitDate(e.target.value)} />
                        <Button variant="contained" onClick={handleSubmit} className="w-100 " type="submit">Spawn Zellits</Button>
                    </Box>

                    <Button variant="contained" onClick={testFollowed} className="w-100 " type="submit">Test Followed</Button>
                    <TextField id="ZellitUID" label="Zellit UID" value={zellitId} required variant="standard" onChange={e => setZellitId(e.target.value)} />
                    <Button variant="contained" onClick={frequencyOffer} className="w-100 " type="submit">Generate Frequency Offer</Button>
                    <Button variant="contained" onClick={aovOffer} className="w-100 " type="submit">Generate AOV Offer</Button>
                    <Button variant="contained" onClick={referralOffer} className="w-100 " type="submit">Generate Referral Offer</Button>
                    <Button variant="contained" onClick={generalOffer} className="w-100 " type="submit">Generate General Offer</Button>
                    <Button variant="contained" onClick={calculateBrandSummaryData} className="w-100 " type="submit">Calculate Brand Summary Data</Button>
                </CardContent>
            </Card>
        </div>
    );
}
export default TestData